import React from 'react';
import get from 'lodash/get';

import Section from './Atoms/Section';
import theme from '../styles/theme';
import Container from './Atoms/Container';
import Row from './Atoms/Row';
import Column from './Atoms/Column';
import Heading from './Atoms/Heading';

function PageHeader({
  bgImage, title, subtitle, ...rest
}) {
  return (
    <Section
      pt={8}
      pb={6}
      background={`url(${get(bgImage, 'image.childImageSharp.fluid.src', get(bgImage, 'image', ''))})`}
      backgroundSize="cover"
      backgroundPosition="center"
      {...rest}
    >
      <Container>
        <Row>
          <Column>
            <Heading h={4} color={theme.color.white}>
              {subtitle}
            </Heading>
            <Heading h={1} color={theme.color.white}>
              {title}
            </Heading>
          </Column>
        </Row>
        <Row />
      </Container>
    </Section>
  );
}

export default PageHeader;
