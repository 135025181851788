import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';

import Dropzone from 'react-dropzone';
import Paragraph from '../Atoms/Paragraph';
import { makeRequest } from '../../utilis/helpers';
import Button from '../Atoms/Button';
import theme from '../../styles/theme';
import Box from '../Atoms/Box';
import FlexBox from '../Atoms/FlexBox';

class CareerTextForm extends Component {
  commonError = null;

  // ---------- dropzone
  onDrop = (acceptedFiles, rejectedFiles) => {
    // Do something with files
    console.log('onDrop');
    return false;
  }


  onDropAccepted = (setFieldValue, setErrors) => (acceptedFiles) => {
    // Do something with files
    setErrors({});
    setFieldValue('resume', acceptedFiles[0]);
  };

  onDropRejected = setFieldValue => (rejectedFiles) => {
    // Do something with files
    console.log('rejected');
    setFieldValue('resume', null);
  };

  // ---------- form
  submitForm = async (values, actions) => {
    const form = new FormData();
    form.append('user', values.user_name);
    form.append('email', values.email);
    form.append('phone', values.phone);
    form.append('resume', values.resume);

    try {
      const res = await makeRequest({
        data: form,
      });
      actions.setSubmitting(false);
      actions.setStatus({ submitted: 'Thank you for your enquiry. We will reply you as soon as possible.' });
    } catch (e) {
      actions.setSubmitting(false);
      actions.setErrors({ common: 'An error occurs. Please try again later.' });
    }
  };

  render() {
    return (
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          user: '',
          email: '',
          phone: '',
          resume: null,
          acceptMarking: false,
        }}
        validate={(values, props) => {
          this.commonError = null;
          const errors = {};
          if (!values.user_name) {
            errors.user_name = 'This field is required';
          }
          if (!values.email) {
            errors.email = 'This field is required';
          }
          if (!values.phone) {
            errors.email = 'This field is required';
          }
          if (!values.resume) {
            errors.resume = 'This field is required';
          }
          return errors;
        }}
        onSubmit={async (values, actions) => {
          this.submitForm(values, actions);
        }}
        render={({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setStatus,
          setFieldValue,
          isSubmitting,
          isValid,
          setSubmitting,
          setErrors,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="field">
              <label className="label">
                <FormattedMessage id="career:form.name" defaultMessage="Full Name" />
              </label>
              <div className="control">
                <input
                  name="user_name"
                  className="input"
                  type="text"
                  onChange={(e) => {
                    setErrors({});
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                />
              </div>
              {errors.user_name && (
                <p className="help is-danger">{errors.user_name}</p>
              )}
            </div>
            <div className="field">
              <label className="label">
                <FormattedMessage id="contact:form.email" defaultMessage="Email Address" />
              </label>
              <div className="control">
                <input
                  name="email"
                  className="input"
                  type="email"
                  onChange={(e) => {
                    setErrors({});
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                />
              </div>
              {errors.email && (
                <p className="help is-danger">{errors.email}</p>
              )}
            </div>
            <div className="field">
              <label className="label">
                <FormattedMessage id="contact:form.phone" defaultMessage="Phone number" />
              </label>
              <div className="control">
                <input
                  name="phone"
                  className="input"
                  type="tel"
                  onChange={(e) => {
                    setErrors({});
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                />
              </div>
              {errors.email && (
                <p className="help is-danger">{errors.email}</p>
              )}
            </div>
            <Box pb={3}>
              <Paragraph color={theme.color.blue} pb={2}>
                <FormattedMessage id="career:form.title" defaultMessage="Resume" />
              </Paragraph>
              <Box className="file has-name is-fullwidth">
                <Dropzone
                  multiple={false}
                  accept="image/jpeg, image/png, application/pdf"
                  ref={this.zoneRef}
                  onDrop={this.onDrop}
                  onDropAccepted={this.onDropAccepted(setFieldValue, setErrors)}
                  onDropRejected={this.onDropRejected(setFieldValue)}
                >
                  {({
                    getRootProps,
                    getInputProps,
                    isDragActive,
                    isDragAccept,
                    isDragReject,
                    isFocused,
                    open,
                    ...rest
                  }) => (
                    <label className="file-label">
                      <input
                        className="file-input is-danger"
                        type="file"
                        name="resume"
                        {...getInputProps()}
                      />
                      <span className="file-cta">
                        <span className="file-label">Choose a file…</span>
                      </span>
                      <span className="file-name">{values.resume ? values.resume.name : ''}</span>
                    </label>
                  )}
                </Dropzone>
              </Box>
              {errors.resume && <p className="help is-danger">{errors.resume}</p>}
            </Box>
            <FlexBox
              width="100%"
              flexWrap="nowrap"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <label className="checkbox">
                <input
                  type="checkbox"
                  name="acceptMarking"
                  value={values.acceptMarking}
                  onChange={handleChange}
                />
              </label>
              <Paragraph display="inline-block" pl={2} color={theme.color.blue}>
                <FormattedMessage
                  id="career:form.tnc"
                  defaultMessage="I agree to receive emails, news, events, and opportunities at Sapphire Holdings"
                />
              </Paragraph>
            </FlexBox>
            <Paragraph fontSize={1}>
              <FormattedMessage id="contact:form.remark" defaultMessage="Primech Holdings is committed to protecting your information. Your information will be used in accordance with the applicable data privacy, our internal policies and our privacy policy." />
            </Paragraph>
            {status && status.submitted && (
              <Paragraph fontSize={1} color={theme.color.blue}>
                {status.submitted}
              </Paragraph>
            )}
            {errors.common && (
              <Paragraph fontSize={1} color={theme.color.red}>
                {errors.common}
              </Paragraph>
            )}
            <div className="control">
              <Button
                primary
                type="submit"
                text="submit"
                loading={isSubmitting}
                disabled={isSubmitting || !isValid}
              />
            </div>
          </form>
        )}
      />
    );
  }
}

export default CareerTextForm;
