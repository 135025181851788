import React from 'react';
import {  graphql } from 'gatsby';

import { FormattedMessage } from 'react-intl';
import { PageHelmet } from '../components/PageHelmet';
import PageHeader from '../components/PageHeader';
import CareerFormSection from '../components/Career/CareerFormSection';

// ********************************* UI Template (Shared with CMS) *********************************
export const CareerPageTemplate = ({
  title,
  description,
  bgImage,
  profileIntro,
}) => (
  <div>
    <PageHelmet title={title} description={description} />
    <PageHeader
      title={<FormattedMessage id="career:title" defaultMessage="Career" />}
      bgImage={bgImage}
      height={300}
    />
    <CareerFormSection {...profileIntro} />
  </div>
);

// ********************************* Render page *********************************
const CareerPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return <CareerPageTemplate {...frontmatter} />;
};

export default CareerPage;

// ********************************* Data graphql *********************************
export const pageQuery = graphql`
  query CareerPageWithId($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        bgImage {
          alt
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        intro {
          title
          desc
          img {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        profileIntro {
          title
          desc
          img {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
