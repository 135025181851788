import React from 'react';
import { FormattedMessage } from 'react-intl';
import Box from '../../Atoms/Box';
import theme from '../../../styles/theme';
import Button from '../../Atoms/Button';
import FlexBox from '../../Atoms/FlexBox';
import Heading from '../../Atoms/Heading';
import Paragraph from '../../Atoms/Paragraph';

function Finish({ goIntro }) {
  return (
    <>
      <Box color={theme.color.blue} pt={5} px={5} textAlign="center">
        <Heading h={2} width="100%">
          <FormattedMessage id="career:form.finish.title" defaultMessage="Thank you for your application." />
        </Heading>
        <Paragraph width="100%">
          <FormattedMessage id="career:form.finish.content" defaultMessage="While you may receive a response much sooner, please allow for up to six weeks for a decision by our recruiting team. Thank you for your interest in employment at Primech Holdings." />
        </Paragraph>
        <FlexBox p={5}>
          <Button primary onClick={goIntro}>
            <FormattedMessage id="career:form.backToStart" defaultMessage="Done" />
          </Button>
        </FlexBox>
      </Box>
    </>
  );
}

export default Finish;
