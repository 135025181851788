import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import Dropzone from 'react-dropzone';

import Box from '../../Atoms/Box';
import Paragraph from '../../Atoms/Paragraph';
import theme from '../../../styles/theme';
import FlexBox from '../../Atoms/FlexBox';
import Button from '../../Atoms/Button';
import { makeRequest } from '../../../utilis/helpers';

class Form extends Component {
  // ---------- dropzone
  onDrop = (acceptedFiles, rejectedFiles) => {
    // Do something with files
    return false;
  };

  onDropAccepted = setFieldValue => (acceptedFiles) => {
    // Do something with files
    setFieldValue('resume', acceptedFiles[0]);
  };

  onDropRejected = setFieldValue => (rejectedFiles) => {
    // Do something with files
    console.log('rejected');
    setFieldValue('resume', null);
  };

  submitForm = async (values, actions) => {
    const form = new FormData();
    form.append('resume', values.resume);

    try {
      const res = await makeRequest({
        data: form,
      });
      actions.setSubmitting(false);
      this.props.goNextStep();
    } catch (e) {
      console.log(e);
      actions.setSubmitting(false);
      actions.setErrors({ common: 'An error occurs. Please try again later.' });
    }
  };

  render() {
    const { goBackStep } = this.props;

    return (
      <Box p={[3, 3, 5]}>
        <Paragraph color={theme.color.blue}>
          <FormattedMessage id="career:form.title" defaultMessage="Selected file:" />
        </Paragraph>
        <Formik
          initialValues={{
            resume: null,
            acceptMarking: false,
          }}
          validate={(values, props) => {
            const errors = {};
            // if (!values.acceptMarking) {
            //   errors.acceptMarking = 'This field is required';
            // }
            if (!values.resume) {
              errors.resume = 'This field is required';
            }
            return errors;
          }}
          onSubmit={async (values, actions) => {
            this.submitForm(values, actions);
          }}
          render={({
            values,
            errors,
            status,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setStatus,
            setFieldValue,
            isSubmitting,
            isValid,
            setSubmitting,
          }) => (
            <>
              <form onSubmit={handleSubmit}>
                <Box pb={3}>
                  <Box className="file has-name is-fullwidth">
                    <Dropzone
                      multiple={false}
                      accept="image/jpeg, image/png, application/pdf"
                      ref={this.zoneRef}
                      onDrop={this.onDrop}
                      onDropAccepted={this.onDropAccepted(setFieldValue)}
                      onDropRejected={this.onDropRejected(setFieldValue)}
                    >
                      {({
                        getRootProps,
                        getInputProps,
                        isDragActive,
                        isDragAccept,
                        isDragReject,
                        isFocused,
                        open,
                        ...rest
                      }) => (
                        <label className="file-label">
                          <input
                            className="file-input is-danger"
                            type="file"
                            name="resume"
                            {...getInputProps()}
                          />
                          <span className="file-cta">
                            <span className="file-label">Choose a file…</span>
                          </span>
                          <span className="file-name">{values.resume ? values.resume.name : ''}</span>
                        </label>
                      )}
                    </Dropzone>
                  </Box>
                  {errors.file && <p className="help is-danger">{errors.file}</p>}
                </Box>
                <FlexBox
                  width="100%"
                  flexWrap="nowrap"
                  alignItems="flex-start"
                >
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="acceptMarking"
                      value={values.acceptMarking}
                      onChange={handleChange}
                    />
                  </label>
                  <Paragraph display="inline-block" pl={2} color={theme.color.blue}>
                    <FormattedMessage
                      id="career:form.tnc"
                      defaultMessage="I agree to receive emails, news, events, and opportunities at Sapphire Holdings"
                    />
                  </Paragraph>
                </FlexBox>
                {errors.common && (
                  <FlexBox width="100%">
                    <Paragraph fontSize={1} color={theme.color.red}>
                      {errors.common}
                    </Paragraph>
                  </FlexBox>
                )}
                <FlexBox>
                  <Button width="44%" text="cancel" onClick={goBackStep} />
                  <Button
                    primary
                    width="44%"
                    text="submit"
                    type="submit"
                    ml="auto"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  />
                </FlexBox>
              </form>
            </>
          )}
        />
      </Box>
    );
  }
}

export default Form;
