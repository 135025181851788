import React, { Component } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';

import Box from '../Atoms/Box';
import Intro from './CareerForm/Intro';
import Form from './CareerForm/Form';
import Finish from './CareerForm/Finish';

const StyledWrapper = styled(Box)`
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.05);
`;

@observer
class CareerForm extends Component {
  @observable step = 1;

  @action.bound
  goNextStep() {
    this.step += 1;
  }

  @action.bound
  goBackStep() {
    this.step -= 1;
  }

  @action.bound
  goIntro() {
    this.step = 1;
  }

  render() {
    const { coverImage } = this.props;

    return (
      <StyledWrapper>
        {this.step === 1 && <Intro coverImage={coverImage} goNextStep={this.goNextStep} />}
        {this.step === 2 && <Form goNextStep={this.goNextStep} goBackStep={this.goBackStep} />}
        {this.step === 3 && <Finish goIntro={this.goIntro} />}
      </StyledWrapper>
    );
  }
}

export default CareerForm;
