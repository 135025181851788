import React from 'react';
import { FormattedMessage } from 'react-intl';
import Box from '../../Atoms/Box';
import theme from '../../../styles/theme';
import PreviewCompatibleImage from '../../Atoms/PreviewCompatibleImage';
import Button from '../../Atoms/Button';
import FlexBox from '../../Atoms/FlexBox';

function Intro({ goNextStep, coverImage }) {
  return (
    <>
      <Box color={theme.color.blue}>
        <PreviewCompatibleImage imageInfo={coverImage} />
      </Box>
      <Box>
        <FlexBox p={5}>
          <Button primary onClick={goNextStep}>
            <FormattedMessage id="career:form.start" defaultMessage="Upload a resume/CV" />
          </Button>
        </FlexBox>
      </Box>
    </>
  );
}

export default Intro;
