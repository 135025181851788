import React, { Component } from 'react';
import Section from '../Atoms/Section';
import Container from '../Atoms/Container';
import Column from '../Atoms/Column';
import Box from '../Atoms/Box';
import Row from '../Atoms/Row';
import CareerForm from './CareerForm';
import CareerTextForm from './CareerTextForm';

class CareerFormSection extends Component {
  render() {
    const {
      title, desc, img, link, minHeight = 'auto', ...rest
    } = this.props;

    return (
      <Section minHeight={minHeight} {...rest}>
        <Container>
          <Row>
            {/* <Column order={2}> */}
            {/* <Box m="auto"> */}
            {/*   <CareerForm coverImage={img} /> */}
            {/* </Box> */}
            {/* </Column> */}
            <Column mt={-6} order={1} py={[5, 5, 3]} col="is-10 is-offset-1" background="white">
              <CareerTextForm />
            </Column>
          </Row>
        </Container>
      </Section>
    );
  }
}

export default CareerFormSection;
